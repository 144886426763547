import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import { Color, Layout, Text as _Text } from '../../constants'

export class Input extends Component {
  state = {
    inFocus: false,
    value: null,
    error: null,
  }

  static defaultProps = {
    label: 'First Name',
    name: 'first_name',
    type: 'default',
    inputType: 'text',
    required: false,
  }

  borderState() {
    const { type, required } = this.props

    if (type === 'disable') {
      return `1px dashed ${Color.LIGHT_GRAY}`
    } else if (type === 'active' || this.state.inFocus) {
      return `2px solid ${Color.GREEN}`
    } else if (required && (type === 'error' || this.state.error)) {
      return `2px solid ${Color.RED}`
    }

    return `1px solid ${Color.BORDER_GRAY}`
  }

  labelState() {
    const { type, dark } = this.props

    // if (type === 'active' || this.state.inFocus) {
    //   return Color.GREEN
    // } else if (type === 'error' || this.state.error) {
    //   return Color.ERROR
    // } else if (dark) {
    //   return Color.WHITE
    // }

    return Color.DEFAULT
  }

  handleFocus = () => {
    this.setState({ inFocus: !this.state.inFocus, error: null })
  }

  handleBlur = e => {
    this.setState({ inFocus: false })
    if (!e.target.value) {
      this.setState({
        error: `Please fill out the ${e.target.name} field`,
      })
    } else {
      this.setState({ error: null })
    }
  }

  render() {
    const {
      error,
      label,
      name,
      type,
      inputType,
      masked,
      multiline,
      onChange,
      required,
    } = this.props
    const phoneNumberMask = [
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]

    const styles = {
      wrapper: {
        display: 'grid',
      },
      input: {
        width: '100%',
        height: multiline ? 120 : 48,
        borderRadius: Layout.BORDER_RADIUS,
        marginBottom: Layout.ELEMENT_PADDING * 3,
        marginTop: Layout.ELEMENT_PADDING,
        padding: Layout.INNER_PADDING,
        border: this.borderState(),
        backgroundColor: 'transparent',
        ..._Text.BODY,
      },
      label: {
        color: this.labelState(),
        ..._Text.BODY,
        display: 'flex',
        fontWeight: '500',
      },
      error: {
        color: Color.RED,
        ..._Text.SPAN,
        display: 'flex',
      },
      hidden: {},
    }

    const InputComponent = multiline
      ? 'textarea'
      : masked
      ? MaskedInput
      : 'input'

    return (
      <label htmlFor={name} className={css(styles.wrapper)}>
        <span className={css(styles.label)}>{`${label} ${
          required ? '*' : ''
        }`}</span>
        {
          <InputComponent
            mask={masked ? phoneNumberMask : null}
            className={css(styles.input)}
            name={name}
            type={inputType}
            disabled={type === 'disable'}
            required={required}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        }
      </label>
    )
  }
}

Input.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  inputType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.any, // TODO: fix type to be boolean.
}

export default Input
