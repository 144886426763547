import React, { Component } from 'react'
import { navigate } from 'gatsby-link'
import axios from 'axios'
import Button from '../Button'
import Input from '../Input'
import { Layout } from '../../constants'
export { default as ContactForm } from './ContactForm'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      first_name: null,
      last_name: null,
      phone: null,
      message: null,
      email: null,
      bot_field: null,
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const {
      first_name,
      last_name,
      phone,
      email,
      message,
      bot_field,
    } = this.state

    const validForm = first_name && message && email && !bot_field

    if (validForm) {
      axios
        .post('/.netlify/functions/contact', {
          first_name,
          last_name,
          phone,
          email,
          message,
        })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => console.error(error))
    }
  }

  render() {
    const {
      formName,
      btnText,
      btnType,
      btnAlignment,
      disabled,
      render,
    } = this.props

    return (
      <form
        id="form"
        name={formName}
        method="post"
        action="/thanks/"
        style={{ width: '100%' }}
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot_field" onChange={this.handleChange} />
          </label>
        </div>
        {render(this.handleChange)}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: btnAlignment,
            marginTop: Layout.ELEMENT_PADDING * 2,
          }}
        >
          <Button
            btnType={btnType}
            btnText={btnText}
            disabled={disabled}
            type="submit"
          />
        </div>
      </form>
    )
  }
}

export default Form

Form.defaultProps = {
  formName: 'quote',
  placeHolder: 'Email Address',
  inputName: 'email',
  btnText: 'Submit',
  btnType: 'formCta',
  btnAlignment: 'flex-end',
  disabled: false,
  render: onChange => (
    <Input
      type="email"
      className="input-cta-form"
      name="email"
      placeholder="email"
      required
      onChange={onChange}
    />
  ),
}
