import { css } from 'emotion'

import { Layout, Color } from '../../constants'

export const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    '@media (min-width: 840px)': {
      flexDirection: 'row',
    },
  },
  rowWrapperRight: {
    paddingRight: 0,
    width: '100%',
    '@media (min-width: 840px)': {
      paddingRight: Layout.INNER_PADDING,
      width: '50%',
    },
  },
  rowWrapperLeft: {
    paddingLeft: 0,
    width: '100%',
    '@media (min-width: 840px)': {
      paddingLeft: Layout.INNER_PADDING,
      width: '50%',
    },
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingRight: Layout.EDGE_PADDING,
    paddingLeft: Layout.EDGE_PADDING,
    // marginTop: 120,
    maxWidth: 600,
    width: '100%',
    '@media (min-width: 840px)': {
      // maxWidth: '70vw', // media query
      // marginLeft: '15vw', // media query
      // marginRight: '15vw', // media query
    },
  },
  formHeader: {
    fontSize: Layout.TITLE + 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: Layout.EDGE_PADDING,
  },
  formText: {
    marginBottom: Layout.EDGE_PADDING * 2,
  },
  phoneNumber: {
    color: Color.PRIMARY_CTA,
  },
}

export const linkListItem = css`
  position: relative;
  color: ${Color.PRIMARY_CTA};
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    border-bottom: 1px solid ${Color.PRIMARY_CTA};
  }
  &:hover:after {
    width: 100%;
  }
`
