import React, { Component } from 'react'
import { css } from 'emotion'
import { get } from 'lodash'

import { Layout, Color } from '../../constants'
import Input from '../Input'
import Form from './index'
import { styles, linkListItem } from './styles'

export default class ContactForm extends Component {
  render() {
    const { form } = this.props
    const formText = get(form, 'text')
    const phoneNumber = get(form, 'phoneNumber')
    const formTextSplit = formText && formText.split('{link}')

    return (
      <div className={css(styles.formContainer)} id="contact">
        <div className={css(styles.formWrapper)}>
          <h2 className={css(styles.formHeader)}>{get(form, 'title')}</h2>
          {formText && (
            <p className={css(styles.formText)}>
              {formTextSplit[0]}{' '}
              <a className={linkListItem} href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>{' '}
              {formTextSplit[1]}
            </p>
          )}
          <Form
            btnText="Submit"
            btnType="cta"
            btnAlignment="flex-start"
            render={handleChange => (
              <>
                <div className={css(styles.row)}>
                  <div
                    className={css(styles.rowWrapperRight, {
                      paddingRight: Layout.INNER_PADDING,
                    })}
                  >
                    <Input
                      inputType="text"
                      name="first_name"
                      label="First Name"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className={css(styles.rowWrapperLeft)}>
                    <Input
                      inputType="text"
                      name="last_name"
                      label="Last Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* <div className={css(styles.row)}>
                  <div className={css(styles.rowWrapperRight)}>
                    <Input
                      masked={true}
                      inputType="tel"
                      name="phone"
                      label="Phone"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className={css(styles.rowWrapperLeft)}>
                    <Input
                      inputType="text"
                      name="zip"
                      label="Zip Code"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div> */}
                {/* <Input
                  inputType="text"
                  name="address"
                  label="Service Address"
                  required
                  onChange={handleChange}
                /> */}
                <Input
                  inputType="email"
                  name="email"
                  label="Email"
                  required
                  onChange={handleChange}
                />
                <Input
                  inputType="message"
                  name="message"
                  label="How Can We Help?"
                  onChange={handleChange}
                  multiline
                  required
                />
              </>
            )}
          />
        </div>
      </div>
    )
  }
}
