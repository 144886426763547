import React, { Component } from 'react'
import { css } from 'emotion'
import Layout from '../components/Layout'
import { Layout as _Layout, Text, Color } from '../constants'
import { ContactForm } from '../components/Form'
import Hero from '../components/Hero'
import Icon from '../components/Icons'

export const ContactPageTemplate = ({ hero, intro, links }) => {
  return (
    <div>
      <Hero {...hero} />
      <div className={css(styles.container)}>
        <h2 className={css(styles.header)}>{intro.heading}</h2>
        <h3 className={css(styles.description)}>{intro.description}</h3>

        <ul className={css(styles.linkContainer)}>
          {links &&
            links.map((item, i) => (
              <li className={css(linkListItem)} key={`${item.text}-${i}`}>
                <a href={item.action} className={css(styles.link)}>
                  <Icon
                    type={item.icon}
                    width={item.iconWidth}
                    height={item.iconHeight}
                    fill={Color.PRIMARY}
                  />
                  <span className={css(styles.text)}>{item.text}</span>
                </a>
              </li>
            ))}
        </ul>

        <div className={css(styles.formWrapper)}>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}

const ContactPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    navbarData,
    footerData,
  } = data

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <ContactPageTemplate
        hero={frontmatter.hero}
        intro={frontmatter.intro}
        links={frontmatter.links}
      />
    </Layout>
  )
}

export default ContactPage

export const contactQuery = graphql`
  query ContactPage($id: String!) {
    ...LayoutFragment
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          type
          button
        }
        intro {
          heading
          description
          email
          phoneNumber
        }
        links {
          icon
          iconWidth
          iconHeight
          action
          text
        }
      }
    }
  }
`

const linkListItem = css`
  font-size: 16px;
  color: ${Color.PRIMARY};
  margin-right: 16px;
  height: 64px;
  text-align: left;
  &:hover a {
    color: ${Color.PRIMARY};
    transition: all 0.2s ease-in-out;
  }
`

const styles = {
  container: {
    paddingTop: 0,
    paddingBottom: 80,
    paddingLeft: _Layout.EDGE_PADDING * 1.5,
    paddingRight: _Layout.EDGE_PADDING * 1.5,
  },
  formWrapper: {
    // paddingTop: 80,
  },
  header: {
    ...Text.PAGE_HEADING,
    textAlign: 'center',
  },
  description: {
    ...Text.BODY,
    maxWidth: 600,
    margin: 'auto',
    textAlign: 'center',
    paddingTop: _Layout.EDGE_PADDING * 2,
    paddingBottom: _Layout.EDGE_PADDING,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 640px)': {
      flexDirection: 'column',
      paddingRight: 0,
    },
    justifyContent: 'space-between',
    maxWidth: 600,
    margin: 'auto',
    paddingLeft: _Layout.EDGE_PADDING,
    paddingRight: _Layout.EDGE_PADDING,
    paddingTop: _Layout.EDGE_PADDING,
    paddingBottom: _Layout.EDGE_PADDING,
  },
  link: {
    // width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: _Layout.EDGE_PADDING,
    paddingBottom: _Layout.EDGE_PADDING,
    color: Color.DEFAULT,
    fontWeight: '500',
    letterSpacing: 0.5,
  },
  text: {
    paddingLeft: _Layout.EDGE_PADDING,
  },
}
